import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {css} from '@emotion/core';
import {Button, Responsive} from 'semantic-ui-react';
import {
  faChevronLeft,
  faChevronRight,
  faBars,
  faClock,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {themeColors} from '../styles';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {CourseAttemptProgressDto, EnrollmentsService} from '../api/generated';
import {notifications} from '../utils/notification-service';
import {Breakpoints, Media} from '../styles/breakpoints';
import {lighten} from 'polished';
import {MobileNavigationSlideOut} from './mobile-navigation-slide-out';
import {useTransition} from 'react-spring';
import {useWindowSize} from 'react-use';
import {
  CompletionStatuses,
  ContentContainerTypes,
} from '../api/generated/enums';
import {buildPath} from '../routes/utils';
import {routes} from '../routes';
import {useUser} from '../auth/use-auth';
import {AssessmentProgress} from './assessment-progress/assessment-progress';

export type ContentItem = {
  title: string;
  value?: string;
  orderKey: string;
  type: ContentContainerTypes;
};

const ContentRenderer = ({className = '', content, refetchEnrollment}) => {
  const user = useUser();
  const history = useHistory();
  const match = useRouteMatch<{
    courseId: string;
    enrollmentId: string;
  }>();

  const [courseAttempt, setCourseAttempt] = useState<
    CourseAttemptProgressDto
  >();
  const [currentContentItemIndex, setCurrentContentItemIndex] = useState<
    number
  >(-1);
  const [
    furthestContentItemVisitedIndex,
    setFurthestContentItemVisitedIndex,
  ] = useState<number>(0);
  const [secondsRemaining, setSecondsRemaining] = useState<number>(1);

  const shouldSkipTimers = localStorage.getItem('skip-timers') === 'true';
  const nextButtonEnabled =
    shouldSkipTimers ||
    furthestContentItemVisitedIndex > currentContentItemIndex ||
    secondsRemaining <= 0;

  const courseId = Number(match.params.courseId);
  const enrollmentId = Number(match.params.enrollmentId);

  const orderedContentItems = useMemo(
    () =>
      content.flattenedContent?.map((x, i) => {
        return {...x, index: i, navId: `menu-nav-${i}`};
      }),
    [content]
  );

  const currentContentItem = useMemo(() => {
    var result = orderedContentItems[currentContentItemIndex] ?? {};
    return result;
  }, [orderedContentItems, currentContentItemIndex]);

  const updateContentProgress = useCallback(async () => {
    if (furthestContentItemVisitedIndex >= currentContentItemIndex) {
      return;
    }

    const result = await EnrollmentsService.updateCourseAttemptProgressByEnrollmentId(
      {
        id: enrollmentId,
        body: {
          currentContentContainerType: currentContentItem.contentType,
          currentPageId: currentContentItem.id,
          enrollmentId,
        },
      }
    );

    if (result.hasErrors) {
      notifications.errors(result.errors);
    } else {
      setFurthestContentItemVisitedIndex(currentContentItemIndex);
    }
  }, [
    currentContentItem.id,
    currentContentItem.contentType,
    currentContentItemIndex,
    enrollmentId,
    furthestContentItemVisitedIndex,
  ]);

  const fetchActiveCourseAttempt = useCallback(async () => {
    const result = await EnrollmentsService.getCourseAttemptProgressByEnrollmentId(
      {
        id: enrollmentId,
      }
    );

    if (result.hasErrors) {
      notifications.errors(result.errors);
    } else if (result.data) {
      if (result.data.contentStatus === CompletionStatuses['Not Started']) {
        const path = buildPath(routes.organization.course.contentStart, {
          courseId,
          enrollmentId,
        });
        history.push(path);
      } else {
        setCourseAttempt(result.data);
      }
    }
  }, [courseId, enrollmentId, history]);

  const navigateToAssessment = useCallback(() => {
    const pathToAssessment = buildPath(routes.organization.course.assessment, {
      courseId,
      enrollmentId,
      assessmentId: Number(courseAttempt?.inProgressAssessmentAttemptId),
    });

    history.push(pathToAssessment);
  }, [courseId, enrollmentId, courseAttempt, history]);

  const navigateToEnrollmentResults = useCallback(() => {
    const pathToEnrollmentResults = buildPath(
      routes.organization.course.enrollmentResults,
      {
        courseId,
        enrollmentId,
      }
    );

    history.push(pathToEnrollmentResults);
  }, [courseId, enrollmentId, history]);

  useEffect(() => {
    updateContentProgress();
  }, [currentContentItemIndex, updateContentProgress]);

  useEffect(() => {
    if (!courseAttempt) {
      fetchActiveCourseAttempt();
    }
    if (
      courseAttempt?.attemptsRemaining === 0 &&
      courseAttempt?.contentStatus === CompletionStatuses.Failed
    ) {
      navigateToEnrollmentResults();
    }

    if (courseAttempt?.contentStatus === CompletionStatuses.Passed) {
      navigateToEnrollmentResults();
    }

    if (!!courseAttempt?.inProgressAssessmentAttemptId) {
      navigateToAssessment();
    }
  }, [
    courseAttempt,
    navigateToEnrollmentResults,
    fetchActiveCourseAttempt,
    navigateToAssessment,
  ]);

  useEffect(() => {
    const shouldTryToSetCurrentPageToLastViewed =
      orderedContentItems.length && currentContentItemIndex === -1;
    if (!shouldTryToSetCurrentPageToLastViewed || !courseAttempt) {
      return;
    }

    const currentPageIsNotLastViewed =
      courseAttempt?.currentPageId !== currentContentItem.id ||
      courseAttempt?.currentPageType !== currentContentItem.type;

    if (currentPageIsNotLastViewed) {
      const setCurrentPageToFirstPage =
        !courseAttempt?.currentPageId && currentContentItemIndex === -1;
      const lastContentItemViewedIndex = setCurrentPageToFirstPage
        ? 0
        : orderedContentItems.findIndex(
            (x) =>
              x.id === courseAttempt?.currentPageId &&
              x.contentType === courseAttempt?.currentPageType
          );

      setFurthestContentItemVisitedIndex(lastContentItemViewedIndex);
      setCurrentContentItemIndex(lastContentItemViewedIndex);
    }
  }, [
    courseAttempt,
    currentContentItemIndex,
    orderedContentItems,
    currentContentItem.id,
    currentContentItem.contentType,
    currentContentItem.type,
  ]);

  useEffect(() => {
    const secondsRemainingInterval = window.setInterval(() => {
      setSecondsRemaining((x) => (x <= 0 ? x : x - 1));
    }, 1000);

    return () => {
      clearInterval(secondsRemainingInterval);
    };
  }, []);

  useEffect(() => {
    setSecondsRemaining(currentContentItem.creditTimeSeconds ?? 0);
  }, [currentContentItem]);

  const showNextPage = useCallback(() => {
    setCurrentContentItemIndex(currentContentItemIndex + 1);
  }, [currentContentItemIndex]);

  const showPreviousPage = useCallback(() => {
    setCurrentContentItemIndex(currentContentItemIndex - 1);
  }, [currentContentItemIndex]);

  const showChapterPage = useCallback(
    (chapterId, resetChapter) => {
      const index = orderedContentItems.findIndex(
        (x) =>
          x.contentType === ContentContainerTypes.Chapter && x.id === chapterId
      );
      setCurrentContentItemIndex(index);

      if (resetChapter) {
        setFurthestContentItemVisitedIndex(index);
      }
    },
    [orderedContentItems]
  );

  const showFirstChapterPage = useCallback(() => {
    const index = orderedContentItems.findIndex(
      (x) => x.contentType === ContentContainerTypes.Chapter
    );
    setCurrentContentItemIndex(index);
  }, [orderedContentItems]);

  const canNavigateToPreviousPage = currentContentItem.isFinal
    ? !currentContentItem?.assessmentAttempts?.length
    : currentContentItemIndex > 0;

  const canNavigateToNextPage =
    currentContentItemIndex < orderedContentItems.length - 1 &&
    (currentContentItem.contentType !== ContentContainerTypes.Assessment ||
      currentContentItem.isPassed);

  const attemptsRemaining = courseAttempt?.attemptsRemaining;
  const navigationProps = useMemo(() => {
    return {
      canNavigateToNextPage,
      canNavigateToPreviousPage,
      enrollmentId,
      content,
      currentContentItem,
      currentContentItemIndex,
      furthestContentItemVisitedIndex,
      orderedContentItems,
      nextButtonEnabled,
      secondsRemaining,
      showChapterPage,
      showFirstChapterPage,
      showPreviousPage,
      showNextPage,
      setCurrentContentItemIndex,
      user,
      refetchEnrollment,
      courseAttempt,
      attemptsRemaining,
    };
  }, [
    canNavigateToNextPage,
    canNavigateToPreviousPage,
    enrollmentId,
    content,
    currentContentItem,
    currentContentItemIndex,
    furthestContentItemVisitedIndex,
    orderedContentItems,
    nextButtonEnabled,
    secondsRemaining,
    showChapterPage,
    showFirstChapterPage,
    showPreviousPage,
    showNextPage,
    user,
    refetchEnrollment,
    courseAttempt,
    attemptsRemaining,
  ]);

  return (
    <div className={`${className} viewer-container`} css={styles}>
      <Responsive minWidth={Breakpoints.TabletMin} as={React.Fragment}>
        <DesktopContentRenderer {...navigationProps} />
      </Responsive>
      <Responsive maxWidth={Breakpoints.MobileMax} as={React.Fragment}>
        <MobileContentRenderer {...navigationProps} />
      </Responsive>
      <KeepInView navId={currentContentItem?.navId} />
    </div>
  );
};

const ContentItemTitle = (props) => {
  const {
    container,
    setCurrentContentItemIndex,
    furthestContentItemVisitedIndex,
    currentContentItemIndex,
  } = props;

  const unlockCourseContent =
    localStorage.getItem('unlock-course-content') === 'true';

  const isSelected = container.index === currentContentItemIndex;
  const isLocked =
    !unlockCourseContent && furthestContentItemVisitedIndex < container.index;

  return (
    <div
      id={container.navId}
      className={`clear title ${
        container.contentType === ContentContainerTypes.Assessment &&
        !container.chapterId
          ? 'final'
          : ''
      } ${container.contentType.toLowerCase()} 
        ${isLocked ? 'locked' : ''}
        ${isSelected ? 'selected' : ''}`}
      onClick={() => {
        !isLocked && setCurrentContentItemIndex(container.index);
      }}
    >
      {`${
        container.contentType === ContentContainerTypes.Chapter ||
        container.contentType === ContentContainerTypes.Assessment
          ? ''
          : '— '
      }${container.title}`}
    </div>
  );
};

const DesktopContentRenderer = (props) => {
  return (
    <div className="desktop">
      <CourseNavigation {...props} />
      <div className="content-container">
        <ContentArea {...props} />
        <DesktopActions {...props} />
      </div>
    </div>
  );
};

const DesktopActions = (props) => {
  const {
    canNavigateToPreviousPage,
    showPreviousPage,
    canNavigateToNextPage,
    nextButtonEnabled,
    showNextPage,
    secondsRemaining,
  } = props;

  return (
    <div className="actions">
      {canNavigateToPreviousPage && (
        <Button onClick={showPreviousPage} primary>
          <FontAwesomeIcon className="left-icon" icon={faChevronLeft} />
          Previous
        </Button>
      )}
      {canNavigateToNextPage && (
        <Button
          className="next"
          disabled={!nextButtonEnabled}
          onClick={showNextPage}
          primary
        >
          {nextButtonEnabled ? 'Next' : secondsRemaining}
          <FontAwesomeIcon
            className="right-icon"
            icon={!nextButtonEnabled ? faClock : faChevronRight}
          />
        </Button>
      )}
    </div>
  );
};

const ContentArea = (props) => {
  const {
    currentContentItem,
    content,
    user,
    showChapterPage,
    showFirstChapterPage,
    enrollmentId,
    refetchEnrollment,
    attemptsRemaining,
  } = props;

  const getQuillHtml = (currentContentItem) => {
    return {
      __html:
        currentContentItem?.value ??
        '<p>--No material has been added for this page.--</p>',
    };
  };

  return (
    <>
      {currentContentItem.contentType !== ContentContainerTypes.Assessment ? (
        <>
          <div className="content-title">{currentContentItem.title}</div>
          <div
            id="content-viewer"
            className="content ql-editor"
            dangerouslySetInnerHTML={getQuillHtml(currentContentItem)}
          />
        </>
      ) : (
        <AssessmentProgress
          assessmentTitle={currentContentItem.title}
          backToChapterStart={showChapterPage}
          backToCourseStart={showFirstChapterPage}
          assessmentPassPercentage={
            currentContentItem.finalAssessmentPassPercentage ??
            currentContentItem.assessmentPassPercentage
          }
          chapterId={currentContentItem.chapterId}
          course={content}
          isFinal={
            currentContentItem.contentType === 'Assessment' &&
            currentContentItem.chapterId === null
          }
          previousAttempts={currentContentItem.assessmentAttempts}
          userName={user.name}
          enrollmentId={enrollmentId}
          refetchEnrollment={refetchEnrollment}
          attemptsRemaining={attemptsRemaining}
        />
      )}
      <div className="actions-phantom" />
    </>
  );
};

const MobileContentRenderer = (props) => {
  const [mobileNavigationOpen, setMobileNavigationOpen] = useState<boolean>(
    false
  );

  const dimensions = useWindowSize();
  const maxHeight = Math.min(dimensions.height, 400);

  const mobileMenuTransitions = useTransition(mobileNavigationOpen, null, {
    from: {
      transform: `translate3d(0,${dimensions.height - 50}px,0)`,
    },
    enter: {
      transform: `translate3d(0,${dimensions.height - maxHeight - 50}px,0)`,
    },
    leave: {transform: `translate3d(0px,${dimensions.height - 50}px,0px)`},
  });

  return (
    <div className="mobile">
      <MobileNavigation
        mobileNavigationOpen={mobileNavigationOpen}
        mobileMenuTransitions={mobileMenuTransitions}
        {...props}
      />
      <div className="content-container">
        <ContentArea {...props} />
      </div>
      <MobileActions
        mobileNavigationOpen={mobileNavigationOpen}
        setMobileNavigationOpen={setMobileNavigationOpen}
        {...props}
      />
    </div>
  );
};

const MobileActions = (props) => {
  const {
    canNavigateToPreviousPage,
    showPreviousPage,
    setMobileNavigationOpen,
    canNavigateToNextPage,
    nextButtonEnabled,
    mobileNavigationOpen,
    showNextPage,
  } = props;

  return (
    <div className="actions">
      <MobileNavigationButton
        disabled={!canNavigateToPreviousPage}
        onClick={showPreviousPage}
      >
        <FontAwesomeIcon className="left-icon" icon={faChevronLeft} />
      </MobileNavigationButton>
      <MobileNavigationButton
        onClick={() => {
          setMobileNavigationOpen(!mobileNavigationOpen);
        }}
      >
        <FontAwesomeIcon className="menu-icon" icon={faBars} />
      </MobileNavigationButton>
      <MobileNavigationButton
        disabled={!canNavigateToNextPage || !nextButtonEnabled}
        onClick={showNextPage}
      >
        <FontAwesomeIcon
          className="right-icon"
          icon={!nextButtonEnabled ? faClock : faChevronRight}
        />
      </MobileNavigationButton>
    </div>
  );
};

const MobileNavigationButton = (props) => (
  <Button {...props} className="navigation-button"></Button>
);

const MobileNavigation = (props) => {
  return (
    <MobileNavigationSlideOut
      open={props.mobileNavigationOpen}
      height={400}
      getKey={() => `mobile-navigation`}
      scroll
      transitions={props.mobileMenuTransitions}
    >
      <CourseNavigation {...props} />;
    </MobileNavigationSlideOut>
  );
};

const CourseNavigation = (props) => {
  return (
    <div className="navigation-container">
      <div className="navigation-list">
        {props.orderedContentItems.map((container) => {
          return (
            <Fragment key={`${container.contentType}-${container.id}`}>
              <ContentItemTitle container={container} {...props} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

const KeepInView = ({navId}) => {
  const dimensions = useWindowSize();

  useEffect(() => {
    if (navId) {
      if (dimensions.width >= Breakpoints.TabletMin) {
        document.getElementById('content-viewer')?.scrollTo(0, 0);
      } else {
        document.getElementById('sub-content')?.scrollTo(0, 0);
      }
      document
        .getElementById(navId)
        ?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [dimensions.width, navId]);
  return <></>;
};

const styles = css`
  &.viewer-container {
    display: flex;
    flex: 1;
    ${Media('TabletMin')} {
      height: 70vh;
      border: 1px solid ${themeColors.gray200};
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .navigation-container {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-right: 2px solid ${themeColors.gray200};
      min-width: 300px;
      max-width: 300px;

      .navigation-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
      }

      .title {
        color: ${themeColors.blue800};
        text-align: left;
        font-size: 0.8rem;
        cursor: pointer;
        padding: 4px 0px 4px 20px;
        padding-right: 6px;

        &.assessment {
          font-size: 1rem;
          padding-left: 40px;
          &.final {
            font-size: 1.2rem;
            padding-left: 20px;
            border-top: 1px solid ${themeColors.gray200};
          }
        }

        &.page {
          padding-left: 80px;
          text-indent: -12px;
        }

        &.chapter {
          font-size: 1.2rem;
          border-top: 1px solid ${themeColors.gray200};
        }
        &.chapter:first-of-type {
          margin-top: 0px;
          border-top: 0px;
        }

        &.section {
          font-size: 1rem;
          padding-left: 55px;
          text-indent: -15px;
        }

        &:focus {
          color: ${themeColors.blue800} !important;
          outline: none;
        }

        &.selected {
          background-color: ${themeColors.blue00} !important;
          padding-right: 2px;
          margin-right: 0px;
          border-right: 4px solid ${themeColors.blue800};
        }

        &.locked {
          opacity: 0.6;
          cursor: default;
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
  }

  .desktop {
    width: 100%;
    display: flex;
    flex: 1;

    .actions {
      display: flex;
      justify-content: flex-end;

      .ui.button {
        margin-left: 20px;

        svg {
          &.left-icon {
            margin-right: 12px !important;
            margin-left: -5px;
          }

          &.right-icon {
            margin-left: 12px !important;
            margin-right: -5px;
          }
        }
      }
    }

    .content-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      background-color: white;
      padding: 25px 40px 0px;
      height: 100%;
      width: 100%;
      overflow-y: auto;

      .content-title {
        margin-bottom: 24px;
        color: ${themeColors.blue700};
      }

      .content {
        flex: 1;
        color: ${themeColors.gray700};
        font-size: 11pt;
        overflow-y: auto;

        img {
          max-width: 100%;

          &.floating-embed {
            float: left;
            padding: 15px;
          }
        }
      }
    }
  }
  .transcript-content {
    background-color: ${themeColors.gray00};
    padding: 4px;
    border: solid 1px ${themeColors.gray700};
    border-radius: 3px;
    color: ${themeColors.gray700};
    summary {
      background-color: ${themeColors.gray100};
      border-bottom: solid 1px ${themeColors.gray700};
      font-weight: bold;
      margin: -4px -4px 0;
      padding: 4px;
    }
    .transcript-text {
      max-height: 75px;
      overflow-y: auto;
    }
  }

  .mobile {
    width: 100%;
    padding-right: 1rem;

    .navigation-container {
      min-width: 100%;
    }

    .content-container {
      display: block;
      flex: 1;
      flex-direction: column;
      background-color: white;

      width: 100%;

      .content-title {
        color: ${themeColors.blue700};
      }

      .content {
        color: ${themeColors.gray700};
        font-size: 11pt;
        overflow-y: auto;

        img {
          max-width: 100%;

          &.floating-embed {
            float: left;
            padding: 15px;
          }
        }

        /* img,
        .ql-video {
          min-width: 100%;
          max-width: 100%;
          height: auto;
          object-fit: contain;
          padding-left: 5px;
          padding-right: 5px;
        } */
      }
    }

    .actions {
      position: fixed;
      height: 50px;
      left: 0;
      bottom: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      z-index: 2;

      display: flex;
      justify-content: space-evenly;

      .navigation-button {
        height: 100%;

        border-radius: 0;
        border: 1px solid #bbb;
        background-color: #f1f1f1;
        &.disabled {
          color: ${lighten(0.3, themeColors.gray700)};
          opacity: 100% !important;
        }
      }

      .ui.button {
        margin: 0;
        padding: 0;
        min-width: 33.33%;
        flex-grow: 1;
        border-radius: 0;
        border: 1px solid #bbb;
      }
    }

    .actions-phantom {
      display: block;
      padding: 0px;
      height: 50px;
      width: 100%;
    }
  }
`;

export default ContentRenderer;
