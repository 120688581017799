import React, {useMemo, useState} from 'react';
import {css} from '@emotion/core';
import {Button} from 'semantic-ui-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faUndoAlt} from '@fortawesome/pro-regular-svg-icons';
import {
  AssessmentAttemptResultDto,
  EnrollmentsService,
} from '../../api/generated';

import {AssessmentResults} from './assessment-results';
import {StartAssessment} from './start-assessment';
import {useHistory} from 'react-router-dom';
import {routes} from '../../routes';
import {Media} from '../../styles/breakpoints';
import {AssessmentAttemptTypes} from '../../api/generated/enums';

const sortAttempts = (attemptA, attemptB) => {
  if (attemptA.dateTaken < attemptB.dateTaken) {
    return 1;
  }
  if (attemptA.dateTaken > attemptB.dateTaken) {
    return -1;
  }
  return 0;
};

export const AssessmentProgress = ({
  assessmentPassPercentage,
  assessmentTitle,
  backToChapterStart,
  backToCourseStart,
  chapterId,
  course,
  isFinal,
  previousAttempts = [],
  userName,
  enrollmentId,
  refetchEnrollment,
  attemptsRemaining,
}) => {
  const history = useHistory();
  const [hasFailedAssessment, setHasFailedAssessment] = useState(false);
  const [willRetry, setWillRetry] = useState(false);
  const attemptResultToShow = useMemo(() => {
    if (!previousAttempts.length) {
      return;
    }

    let result = (previousAttempts as AssessmentAttemptResultDto[]).find(
      (x) => x.passed
    );

    if (!result) {
      result = previousAttempts.sort(sortAttempts)[0];
    }

    const failResult = !result.passed;

    if (failResult !== hasFailedAssessment) {
      setHasFailedAssessment(failResult);
    }

    return result;
  }, [previousAttempts, hasFailedAssessment]);

  const navigateToDashboard = () => {
    history.push(routes.organization.landing);
  };

  const showStartAssessment =
    !attemptResultToShow ||
    willRetry ||
    (hasFailedAssessment && attemptResultToShow.isFinalized);

  const restartFinalAssessment = async () => {
    await EnrollmentsService.restartCourseByEnrollmentId({
      id: enrollmentId,
    });
    setWillRetry(true);
  };

  const restartChapter = async (chapterId) => {
    await EnrollmentsService.restartChapterByEnrollmentId({
      id: enrollmentId,
    });
    refetchEnrollment();
    backToChapterStart(chapterId, true);
  };

  return (
    <div css={styles}>
      <div className="content-title">{assessmentTitle}</div>
      <div className="content">
        {showStartAssessment && (
          <StartAssessment
            chapterId={chapterId}
            assessmentPassPercentage={assessmentPassPercentage}
            course={course}
            userName={userName}
          />
        )}
        {!showStartAssessment && (
          <>
            <AssessmentResults results={attemptResultToShow} />
            <div className="assessment-actions">
              {hasFailedAssessment ? (
                <>
                  {isFinal && (
                    <Button
                      onClick={() =>
                        isFinal
                          ? backToCourseStart()
                          : backToChapterStart(chapterId)
                      }
                      primary
                    >
                      <FontAwesomeIcon
                        className="left-icon"
                        icon={faChevronLeft}
                      />
                      Study Content
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      isFinal || attemptResultToShow?.isFinalized
                        ? restartFinalAssessment()
                        : restartChapter(chapterId);
                    }}
                    primary
                  >
                    <FontAwesomeIcon className="left-icon" icon={faUndoAlt} />
                    {isFinal || attemptResultToShow?.isFinalized
                      ? 'Retry Assessment'
                      : 'Restart Chapter'}
                  </Button>
                </>
              ) : (
                isFinal && (
                  <>
                    <Button onClick={navigateToDashboard}>
                      Return To Dashboard
                    </Button>
                  </>
                )
              )}
            </div>
            {hasFailedAssessment &&
              attemptResultToShow?.type === AssessmentAttemptTypes.Final && (
                <div className="attempts-remaining">
                  {attemptsRemaining !== null && attemptsRemaining !== undefined
                    ? `Attempts Remaining: ${attemptsRemaining}`
                    : ''}
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

const styles = css`
  height: 100%;
  overflow-y: auto;

  .recipient-name {
    margin-left: 5px;
    font-weight: bold;
  }

  .assessment-actions {
    display: flex;
    justify-content: flex-end;
    .ui.button {
      margin-top: 20px;
      margin-left: 20px;
    }

    ${Media('MobileMax')} {
      flex-direction: column;
      .ui.button {
        display: block;
        width: 100%;
        margin-bottom: 8px;
        margin-top: 8px;
        margin-left: 0;
      }
    }
  }

  .attempts-remaining {
    float: right;
    margin-top: 10px;
  }
`;
