import {css} from '@emotion/core';
import React from 'react';
import Konami from 'react-konami-code';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Button, Header, Segment} from 'semantic-ui-react';
import {
  CompletedEnrollmentSummaryDto,
  EnrollmentsService,
} from '../api/generated';
import {AssessmentAttemptTypes} from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {AssessmentResults} from '../components/assessment-progress/assessment-results';
import {AsyncStateContainer} from '../components/async-state-container';
import {Media} from '../styles/breakpoints';
import {userManager} from '../auth';
import {routes} from '../routes';

const EnrollmentResults = () => {
  const match = useRouteMatch<{
    courseId: string;
    enrollmentId: string;
  }>();
  const enrollmentId = Number(match.params.enrollmentId);

  const fetchEnrollmentResults = useAsync(async () => {
    const {data} = await EnrollmentsService.getCompletedEnrollmentSummary({
      id: enrollmentId,
    });

    return data;
  }, [enrollmentId]);

  const enrollmentResults = fetchEnrollmentResults.value;

  return (
    <BasicPage title="Course Results" css={styles}>
      <AsyncStateContainer {...fetchEnrollmentResults}>
        {enrollmentResults && (
          <>
            {enrollmentResults.assessmentAttemptResults.some(
              (x) =>
                x.isFinalized &&
                x.passed &&
                x.type === AssessmentAttemptTypes.Final
            ) ? (
              <CorseCompleteSuccessfully
                enrollmentId={enrollmentId}
                redirectUrl={enrollmentResults.courseCompletionRedirectUrl}
              />
            ) : (
              <FailedCourse
                redirectUrl={enrollmentResults.courseCompletionRedirectUrl}
              />
            )}
            <AssessmentAttemptsResults enrollmentResults={enrollmentResults} />
          </>
        )}
      </AsyncStateContainer>
    </BasicPage>
  );
};

type CorseCompleteSuccessfullyProps = {
  enrollmentId: number;
  redirectUrl: string;
};

const CorseCompleteSuccessfully: React.FC<CorseCompleteSuccessfullyProps> = ({
  enrollmentId,
  redirectUrl,
}) => {
  return (
    <Segment>
      <Header as="h3" content="Course Complete" />
      <CongratulationsText />
      <div className="actions">
        <ReturnToCallingSystem redirectUrl={redirectUrl} />
      </div>
    </Segment>
  );
};

type FailedCourseSection = {
  redirectUrl: string;
};

const FailedCourse: React.FC<FailedCourseSection> = ({redirectUrl}) => {
  return (
    <Segment>
      <Header as="h3" content="Course Failed" />{' '}
      <div>
        Unfortunately you did not pass the course. Once you are finished
        reviewing your results you may click the return button below for further
        instructions.
      </div>
      <div className="actions">
        <ReturnToCallingSystem redirectUrl={redirectUrl} />
      </div>
    </Segment>
  );
};

type AssessmentAttemptsResultsProps = {
  enrollmentResults: CompletedEnrollmentSummaryDto;
};

const AssessmentAttemptsResults: React.FC<AssessmentAttemptsResultsProps> = ({
  enrollmentResults,
}) => {
  return (
    <Segment className="assessment-attempts">
      <Header as="h3" content="Chapter Assessment Attempts" />
      {enrollmentResults.assessmentAttemptResults
        .filter((x) => x.chapterId)
        .map((x) => (
          <AssessmentResults key={`attempt-results-${x.id}`} results={x} />
        ))}
      <Header as="h3" content="Final Assessment Attempts" />
      {enrollmentResults.assessmentAttemptResults
        .filter((x) => !x.chapterId)
        .map((x) => (
          <AssessmentResults key={`attempt-results-${x.id}`} results={x} />
        ))}
    </Segment>
  );
};

const CongratulationsText = () => {
  return (
    <div>
      Congratulations! You have successfully completed the final assessment. The
      course is now complete. You may print your course certificate and view
      your assessment results below.
      <Konami>
        <iframe
          title="congratulations-ee"
          width="560"
          height="315"
          src={atob(
            'aHR0cHM6Ly93d3cueW91dHViZS5jb20vZW1iZWQvYXZhU2RDMFFPVU0/dD0yNg=='
          )}
          allow={atob(
            'YWNjZWxlcm9tZXRlcjsgYXV0b3BsYXk7IGNsaXBib2FyZC13cml0ZTsgZW5jcnlwdGVkLW1lZGlhOyBneXJvc2NvcGU7IHBpY3R1cmUtaW4tcGljdHVyZQ=='
          )}
        ></iframe>
      </Konami>
    </div>
  );
};

type ReturnToCallingSystemProps = {
  redirectUrl: string;
};

const ReturnToCallingSystem: React.FC<ReturnToCallingSystemProps> = ({
  redirectUrl,
}) => {
  const history = useHistory();

  return (
    <div className="actions">
      <Button
        primary
        onClick={async () => {
          if (!!redirectUrl) {
            await userManager.removeUser();
            userManager.signoutPopup();
            window.location.href = redirectUrl;
          }

          history.push(routes.dashboard);
        }}
      >
        Return to{' '}
        {redirectUrl ? redirectUrl.match('(?<=://).+?[^/:](?=[?/]|$)') : 'Home'}
      </Button>
    </div>
  );
};

const styles = css`
  .actions {
    display: flex;
    justify-content: flex-end;
    .ui.button {
      margin-top: 20px;
      margin-left: 20px;
    }

    ${Media('MobileMax')} {
      flex-direction: column;
      .ui.button {
        display: block;
        width: 100%;
        margin-bottom: 8px;
        margin-top: 8px;
        margin-left: 0;
      }
    }
  }

  .assessment-attempts {
    .table {
      margin-bottom: 20px;
    }
  }
`;

export default EnrollmentResults;
